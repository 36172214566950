.aboutus-main-area{
    text-align: center;
    margin-right: 30px;
    margin-left: 30px;
    
}
.grv-profile img{
    width: 100px;
    height: 100px;
    border-radius: 50px;
    border: 3px solid #555;
    margin-bottom: 20px;
}
 a{
 color: blue;
}
.grv-profile-description  p{
    text-align: justify;
    
}
